import React from 'react'
import kafkaReplicationImage from '../../../resources/images/hanzo/kafka-replication/kafka-replication.webp'
import thumbnailStateOfKafkaReplicationImage from '../../../resources/images/featured_resources/thumbnails/state-of-kafka-replication.webp'
import thumbnailLenses6PanoptesImage from '../../../resources/images/featured_resources/thumbnails/lenses-6-0-panoptes.webp'
import thumbnailPressReleaseImage from '../../../resources/images/featured_resources/thumbnails/press-release.webp'
import authorAndrewStevensonImage from '../../../resources/images/featured_resources/authors/andrew-stevenson.webp'
import authorLensesImage from '../../../resources/images/featured_resources/authors/lenses.webp'
import lensesK2KVideoFeature from '../../../resources/images/hanzo/kafka-replication/lenses-k2k.mp4'
import lensesK2KVideoFeatureOptimized from '../../../resources/images/hanzo/kafka-replication/lenses-k2k.webm'
import { K2KIcon } from './k2k-icon'
import { DemoIcon } from '../../lib/components/basics/icons'

export const heroProps = {
    title: (
        <>
            Confident Kafka replication,
            <br />
            complete autonomy
        </>
    ),
    description: (
        <p>
            Kafka replication meant choosing between cloud providers and clunky operations. Until
            now.
        </p>
    ),
    primaryCtaLabel: 'See it in action',
    primaryCtaLink: '/request-demo/?product=k2k',
}

export const leftRightColumn = {
    title: 'Kafka replication needs to change',
    description: (
        <>
            <p>
                Distributing streaming data across environments and clouds has become an essential
                pattern for a modern data stack, for everything from disaster recovery, to adopting
                a Data Mesh architecture.
            </p>
            <br />
            <p>To be successful, engineers need:</p>
            <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                <li>Self-service control, avoiding dependence on central teams</li>
                <li>Straightforward operations, and great governance</li>
                <li>Reliability, including exactly-once semantics</li>
                <li>Management through GitOps.</li>
            </ul>
            <br />
            <p>
                But there isn’t much choice. Most solutions only solve part of the problem, and
                teams waste time switching between different solutions. More companies are using
                multiple clouds, and need one tool to unify data sharing across different domains.
            </p>
        </>
    ),
    ctas: [
        {
            text: 'Read more',
            href: '/blog/2025/02/state-of-kafka-replication',
        },
    ],
}

export const leftRightColumnImage = {
    imgSrc: kafkaReplicationImage,
    altText: 'Kafka replication',
}

export const plainTextSectionTitle = (
    <>
        Lenses K2K: universal, user-friendly
        <br />
        Kafka replication
    </>
)

export const plainTextSectionDescription =
    'A new Kafka replicator that gives any user the power to easily and reliably share real-time data across their business.'

export const plainTextSectionBlock1Props = {
    title: <div>Vendor agnostic</div>,
    textBlocks: [
        <div key='1'>
            <p>
                To avoid vendor lock-in and replicate data across any cluster and vendor, from
                Confluent Cloud and Redpanda to MSK and Azure Event Hubs.
            </p>
        </div>,
    ],
}

export const plainTextSectionBlock2Props = {
    title: <div>Self-service</div>,
    textBlocks: [
        <div key='1'>
            <p>
                Full self-service capabilities to empower teams to replicate data, all while
                governed by multi-cluster Identity and Access Management and auditing.
            </p>
        </div>,
    ],
}

export const plainTextSectionBlock3Props = {
    title: <div>Enterprise grade</div>,
    textBlocks: [
        <div key='1'>
            <p>Built by the elite engineering team behind the Lenses SQL engines.</p>
        </div>,
    ],
}

export const videoSectionProps = {
    title: 'See the Kafka replicator in action',
    description:
        'Replicate your topic from one Kafka to another with a click. Define your replication in a simple YAML. Lenses does the rest.',
    video: {
        src: lensesK2KVideoFeature,
        srcOptimized: lensesK2KVideoFeatureOptimized,
    },
}

export const featuredResourcesBlock = {
    title: 'Featured Resources',
}

export const promotedResourcesSectionItems = [
    {
        featuredImage: thumbnailStateOfKafkaReplicationImage,
        tag: 'Blog',
        title: 'The state of Kafka replication',
        link: '/blog/2025/02/state-of-kafka-replication',
        author: {
            image: authorAndrewStevensonImage,
            name: 'Andrew Stevenson',
        },
    },
    {
        featuredImage: thumbnailPressReleaseImage,
        tag: 'Press release',
        title: 'Lenses.io introduces new streaming data replicator',
        link: '/blog/2025/02/introducing-streaming-data-replicator/',
        author: {
            image: authorLensesImage,
            name: 'Lenses.io',
        },
    },
    {
        featuredImage: thumbnailLenses6PanoptesImage,
        tag: 'Blog',
        title: 'Introducing Lenses 6.0 Panoptes',
        link: '/blog/2024/11/lenses-6-panoptes-announcement/',
        author: {
            image: authorAndrewStevensonImage,
            name: 'Andrew Stevenson',
        },
    },
]

export const reviewsPreFooter = [
    {
        review: 'Lenses is amazing at providing insights into the Kafka topics',
        author: 'Alexandru-Florian A',
    },
    {
        review: 'The Lenses product is amazing and I am positive it has drastically increased our development times using Kafka.',
        author: 'Matthew G.',
    },
    {
        review: 'Beautiful, responsive UI for kafka overview and with lenses SQL for kafka topics browsing',
        author: 'Ivan V.',
    },
    {
        review: "Lenses provides a quick inside view of all your data flow. There's not much to dislike.",
        author: 'Kyriakos P.',
    },
    {
        review: 'Lenses is an intuitive tool that provides great observability and management features for our streaming',
        author: 'Anonymous',
    },
    {
        review: 'A very respectable add-on for enterprise-grade Kafka clusters!',
        author: 'Donny F.',
    },
    { review: 'Invaluable tool for anyone using Kafka', author: 'Michael G.' },
    {
        review: `Very complete tool to browse and understand what's happening inside your clusters`,
        author: 'George F.',
    },
]

export const prefooterTitle = 'Get a private preview'

export const prefooterCtaLabel = 'See it in action'
export const prefooterCtaLink = '/request-demo/?product=k2k'

export const twoColumnCtaSectionProps = [
    {
        title: 'Join the webinar',
        type: 'primary' as const,
        leadingText:
            'Join our virtual session to learn what you can do with Lenses K2K, and see how it works.',
        buttonText: 'Register now',
        href: '/kafka-replicator-webinar/',
        icon: <K2KIcon key='k2kIcon' />,
    },
    {
        title: 'Get a private preview',
        type: 'secondary' as const,
        leadingText:
            'Get a 1 on 1 demo of Lenses K2K to see how it can work with your Kafka environment.',
        buttonText: 'Schedule',
        href: '/request-demo/?product=k2k',
        icon: <DemoIcon key='demoIcon' />,
    },
]
